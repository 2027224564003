import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegStar, FaRegUser } from 'react-icons/fa'
import { MdArrowForwardIos, MdEventNote, MdLogout } from 'react-icons/md'

const Profile = () => {

    const navigate = useNavigate()

    const logoutFun = () => {
        localStorage.removeItem('limitsUser')
        navigate('/signin')
    }

    return (
        <>
            <div className='main-content'>
                <Header page={"profile"} title={"My Profile"} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='my-profile mb-5'>
                            <img src={require('../assets/images/profile.png')} className='img-fluid img1 d-block mx-auto' alt="" />
                        </div>
                        
                        <div className='profile-links'>
                            <Link to={"/user-profile"} className='link'><div className='d-flex align-items-center'>
                                <div className='w-100 me-2'>
                                    <p className='text-white mb-0'><FaRegUser className='icon1 align-middle' /><span className='align-middle'>General Profile</span></p>
                                </div>
                                <div className='text-end'>
                                    <p className='mb-0 text-white'><MdArrowForwardIos /></p>
                                </div>
                            </div></Link>

                            <Link to={"/past-voting"} className='link'><div className='d-flex align-items-center'>
                                <div className='w-100 me-2'>
                                    <p className='text-white mb-0'><FaRegStar className='icon1 align-middle' /><span className='align-middle'>Past Voting</span></p>
                                </div>
                                <div className='text-end'>
                                    <p className='mb-0 text-white'><MdArrowForwardIos /></p>
                                </div>
                            </div></Link>

                            <Link to={"/participated-events"} className='link'><div className='d-flex align-items-center'>
                                <div className='w-100 me-2'>
                                    <p className='text-white mb-0'><MdEventNote className='icon1 align-middle' /><span className='align-middle'>Participated Events</span></p>
                                </div>
                                <div className='text-end'>
                                    <p className='mb-0 text-white'><MdArrowForwardIos /></p>
                                </div>
                            </div></Link>
                        </div>

                        <div className='mt-5'>
                            <button className='btn btn1 w-100' onClick={logoutFun}><MdLogout className='align-middle fs-4 me-2' /> <span className='align-middle'>Logout</span></button>
                        </div>

                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default Profile
