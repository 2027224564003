import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link, useParams } from 'react-router-dom';
import { useAllContext } from '../context/AllContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoDotFill } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

const Voting = () => {
    const { t, i18n } = useTranslation()

    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const params = useParams()

    const { getAllBattle, allBattle } = useAllContext()

    const [liveBattle, setLiveBattle] = useState({});
    const liveBattleFind = allBattle && allBattle.battles && allBattle?.battles.find(battle => battle.battle_status === "live");

    useEffect(() => {
        if (liveBattleFind) {
            setLiveBattle(liveBattleFind);
        }
    }, [liveBattleFind])

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milliseconds, setMilliseconds] = useState(0);

    useEffect(() => {
        if (liveBattle && allBattle) {
            // Convert battle_time_start to a Date object
            const startTime = new Date(liveBattle.battle_time_start);

            // Calculate the end time by adding the battles_time (in minutes)
            const endTime = new Date(startTime.getTime() + allBattle.battles_time * 60000);

            const updateTimer = () => {
                const now = new Date();
                const timeDiff = endTime - now;

                if (timeDiff > 0) {
                    // Calculate remaining minutes, seconds, and milliseconds
                    const mins = Math.floor((timeDiff / 1000 / 60) % 60);
                    const secs = Math.floor((timeDiff / 1000) % 60);
                    const millis = Math.floor((timeDiff % 1000) / 100); // To show in hundreds of ms

                    // Update individual states
                    setMinutes(mins);
                    setSeconds(secs);
                    setMilliseconds(millis);
                } else {
                    // If time is up
                    setMinutes(0);
                    setSeconds(0);
                    setMilliseconds(0);
                    clearInterval(intervalId); // Stop the interval
                }
            };

            // Declare intervalId here before it's used
            const intervalId = setInterval(updateTimer, 100);

            // Update the timer immediately on mount
            updateTimer();

            // Cleanup the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [allBattle, liveBattle]);


    const submitVote = async (userId) => {
        const apiData = {
            eventId: params.eventId,
            battleId: liveBattle?._id,
            vote_receive_userId: userId,
            vote_send_userId: limitsUser._id,
            vote: 1
        }

        if (liveBattle?.voting_status === "on") {
            
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VotingTo`, apiData, {
                    headers: {
                        Authorization: `${limitsUser.token}`
                    }
                })
                const data = await response.data
    
                if(data.status === 0){
                    toast.error(data.message)
                }
    
                if(data.status === 1){
                    toast.success(data.message)
                    getVotingStatus()
    
                }
    
            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        } else {
            toast.error("Your vote not submitted, Voting stopped")
        }
    }

    const [votingStatus, setVotingStatus] = useState({});
    const getVotingStatus = async () => {
        const apiData = {
            eventId: params.eventId,
            battleId: liveBattle?._id,
            vote_send_userId: limitsUser._id
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VotingStatusActive`, apiData, {
                headers: {
                    Authorization: `${limitsUser.token}`
                }
            })
            const data = await response.data
            

            if(data.status === 0){
                // toast.error(data.message)
            }

            if(data.status === 1){
                toast.success(data.message)
                setVotingStatus(data)

            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
            toast.error(error.response.data.message)
        }
    }

    const totalVotes = votingStatus?.Player1 + votingStatus?.Player2;
    const player1Percentage = ((votingStatus?.Player1 / totalVotes) * 100).toFixed(2).replace(/\.?0+$/, '');
    const player2Percentage = ((votingStatus?.Player2 / totalVotes) * 100).toFixed(2).replace(/\.?0+$/, '');

    useEffect(() => {
        if (liveBattle?._id) {
            getVotingStatus()
        }
    }, [liveBattle])

    useEffect(() => {
        getAllBattle(params.eventId)
    }, [])

    useEffect(() => {
        // Set up an interval to fetch data every second (1000ms)
        const intervalId = setInterval(() => {
            getAllBattle(params.eventId)
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [params.eventId])


    return (
        <>
            <div className='main-content'>
                <Header page={""} title={t("Art Battle Live Stream")} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        {i18n.language === 'en' ? 
                            <h4 className='fw-bold text-center mb-4'>{allBattle?.title_eng}: {liveBattle?.battle1_keyword_eng} / {liveBattle?.battle2_keyword_eng}</h4>
                            :<h4 className='fw-bold text-center mb-4'>{allBattle?.title_jap}: {liveBattle?.battle1_keyword_jap} / {liveBattle?.battle2_keyword_jap}</h4>
                        }

                        <div className='card card1 overflow-hidden'>
                            <div className='card-body p-0'>
                                <div className='live-box position-relative'>
                                    <span className='live-tag'><GoDotFill /> {t('Live now')}</span>
                                    <div className='ratio ratio-16x9' style={{"maxHeight": "75vh"}} dangerouslySetInnerHTML={{ __html: allBattle?.youtube_emd }} />
                                </div>
                            </div>
                        </div>

                        <h4 className='fw-normal text-center mt-4 mb-3'>
                            <span className='d-inline-block me-1'>{String(minutes).padStart(2, '0')}</span>:
                            <span className='d-inline-block' style={{"minWidth": "28px"}}>{seconds < 10 ? '0' : ''}{seconds}</span>:
                            <span className="d-inline-block" style={{"minWidth": "28px"}}>{String(milliseconds).padStart(2, '0')}</span>
                        </h4>

                        <div className='card card1 bg-transparent border-0 mt-2'>
                            <div className='card-body p-0'>
                                <div className='row gx-2 text-center'>
                                    <div className='col-md-4 col-5'>
                                        <Link onClick={() => submitVote(liveBattle?.battle1?._id)}><div className={`player-voting plyr1 ${votingStatus?.userId === liveBattle?.battle1?._id ? 'active' : ''}`}>
                                            {
                                                liveBattle?.battle1?.profileImage ? 
                                                liveBattle?.battle1?.profileImage.includes('google') ? <img src={`${liveBattle?.battle1?.profileImage}`} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                                :<img src={`${process.env.REACT_APP_IMAGES_URL}/${liveBattle?.battle1?.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                                :<img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            }
                                            <h6 className='mb-0 text-white fs-14'>{liveBattle?.battle1?.username}</h6>
                                        </div></Link>
                                        {/* <h6 className='mb-0 text-main fs-14 mt-2'>MY VOTE</h6> */}
                                    </div>
                                    <div className='col-md-4 col-2 align-self-center'>
                                        <h2 className='mb-1 text-white fw-semibold' style={{'fontSize': '36px'}}>VS</h2>
                                    </div>
                                    <div className='col-md-4 col-5'>
                                        <Link onClick={() => submitVote(liveBattle?.battle2?._id)}><div className={`player-voting plyr2 ${votingStatus?.userId === liveBattle?.battle2?._id ? 'active' : ''}`}>
                                            {
                                                liveBattle?.battle2?.profileImage ? 
                                                liveBattle?.battle2?.profileImage.includes('google') ? <img src={`${liveBattle?.battle2?.profileImage}`} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                                :<img src={`${process.env.REACT_APP_IMAGES_URL}/${liveBattle?.battle2?.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                                :<img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '29px', 'minWidth': '29px', 'height': '29px'}} />
                                            }
                                            <h6 className='mb-0 text-white fs-14'>{liveBattle?.battle2?.username}</h6>
                                        </div></Link>
                                    </div>
                                </div>

                                <h6 className='mb-0 text-white text-center fs-14 mt-2'>{t('CAST YOUR VOTE')}!</h6>
                            </div>
                        </div>

                        <div className='mb-3'>
                            {
                                liveBattle?.voting_bar === "show" && <>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <div className=''>
                                            <p className='mb-0 text-white text-center fw-light fs-14 mt-3'>{`${player1Percentage !== NaN ? player1Percentage : "0"}`}%</p>
                                        </div>
                                        <div className=''>
                                            <p className='mb-0 text-white text-center fw-light fs-14 mt-3'>{`${player2Percentage !== NaN ? player2Percentage : "0"}`}%</p>
                                        </div>
                                    </div>
                                    <div className="progress progress1">
                                        <div className="progress-bar" role="progressbar" style={{ "width": `${player1Percentage}%`, "background": "#0500FF" }} aria-valuenow={player1Percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        <div className="progress-bar" role="progressbar" style={{ "width": `${player2Percentage}%`, "background": "#FF0000" }} aria-valuenow={player2Percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </>
                            }

                            { liveBattle?.voting_status === "on" && <p className='mb-0 text-white text-center fs-20 mt-3'>Voting Started!</p>}
                        </div>
                        
                        <h4 className='fw-normal text-center mb-4'>{liveBattle?.battle_name}</h4>
                        <div className='card card1 mt-2'>
                            <div className='card-body'>
                                <p className='text-center fw-semibold text-white'>Event Battles</p>
                                {
                                    allBattle && allBattle?.battles?.filter(items => items.battle_status !== "live").map(items => {
                                        return <div key={items._id} className='row gx-2 mt-4 text-center align-items-center'>
                                            <div className='col-md-4 col-5'>
                                                <div className='player1 plyr1'>
                                                    {
                                                        items.battle1.profileImage ? 
                                                        items.battle1.profileImage.includes('google') ? <img src={`${items.battle1.profileImage}`} className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                        :<img src={`${process.env.REACT_APP_IMAGES_URL}/${items.battle1.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                        :<img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                    }
                                                    <h6 className='mb-0 text-white fs-14'>{items.battle1.username}</h6>
                                                    {/* <h6 className='mb-0 mt-1 fs-12'>USA</h6> */}
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-2'>
                                                <h2 className='mb-1 text-white fw-semibold' style={{'fontSize': '36px'}}>VS</h2>
                                            </div>
                                            <div className='col-md-4 col-5'>
                                                <div className='player1 plyr2'>
                                                {
                                                        items.battle2.profileImage ? 
                                                        items.battle2.profileImage.includes('google') ? <img src={`${items.battle2.profileImage}`} className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                        :<img src={`${process.env.REACT_APP_IMAGES_URL}/${items.battle2.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                        :<img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px'}} />
                                                    }
                                                    <h6 className='mb-0 text-white fs-14'>{items.battle2.username}</h6>
                                                    {/* <h6 className='mb-0 mt-1 fs-12'>JAPAN</h6> */}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                
                            </div>
                        </div>

                    </div>
                </div>

                

                <Menu />
            </div>
        </>
    )
}

export default Voting
