import React, { createContext, useContext, useReducer } from 'react'
import reducer from './AllReducer';
import axios from 'axios';

const AllContext = createContext()

const initialState = {
    upcomingEvent: [],
    pastEvent: [],
    allEvents: [],
    allBattle: []
}

const AllContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const getUpcomingEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/GetUpcomingEvent`)
            const data = await response.data
            
            dispatch({ type: "setUpcomingEvent", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const getPastEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/GetPastEvent`)
            const data = await response.data
            
            dispatch({ type: "setPastEvent", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const getAllEvents = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/AllEventGet`)
            const data = await response.data
            
            dispatch({ type: "setAllEvents", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const getAllBattle = async (eventId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EventByAllBattle/${eventId}`)
            const data = await response.data
            
            dispatch({ type: "setAllBattle", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }
    }

    
    return <AllContext.Provider value={{...state, 
        getUpcomingEvent,
        getPastEvent,
        getAllEvents,
        getAllBattle
    }}>
        {children}
    </AllContext.Provider>
}

const useAllContext = () => {
    return useContext(AllContext)
}

export { AllContextProvider, useAllContext }