import React from 'react'
import { FaReceipt } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Menu = () => {
    return (
        <>
            <div className='menus'>
                <div className='container'>
                    {/* <div className='file-icon'>
                        <FaReceipt />
                        <span className='noti'>53</span>
                    </div> */}

                    <nav className="nav nav-menu d-flex flex-nowrap">
                        <NavLink className="nav-link" to="/">HOME</NavLink>
                        {/* <NavLink className="nav-link" to="/calendar">CALENDAR</NavLink> */}
                        {/* <NavLink className="nav-link" to="/voting">VOTE!</NavLink> */}
                        {/* <NavLink className="nav-link" to="/event-history">HISTORY</NavLink>
                        <NavLink className="nav-link" to="/profile">PROFILE</NavLink> */}
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Menu
