import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { FaRegCalendarAlt, FaRegClock, FaStar } from 'react-icons/fa'

const ParticipatedEvents = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={"profile2"} title={"Profile/Participated Events"} backpage={'/profile'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <span className='me-3'>Type: Loremipsum</span> 
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                    <div className='d-flex me-2'>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                    {/* <div className='d-flex me-2'>
                                        {
                                            items?.participants?.slice(0, 5).map((items, ind) => (
                                                <div key={ind} className='img1'>
                                                    {items.user.profileImage !== "" ? (
                                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                    ) : (
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                    <div>
                                        <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <span className='me-3'>Type: Loremipsum</span> 
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                    <div className='d-flex me-2'>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                    {/* <div className='d-flex me-2'>
                                        {
                                            items?.participants?.slice(0, 5).map((items, ind) => (
                                                <div key={ind} className='img1'>
                                                    {items.user.profileImage !== "" ? (
                                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                    ) : (
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                    <div>
                                        <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <span className='me-3'>Type: Loremipsum</span> 
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                    <div className='d-flex me-2'>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                        <div className='img1'>
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                    {/* <div className='d-flex me-2'>
                                        {
                                            items?.participants?.slice(0, 5).map((items, ind) => (
                                                <div key={ind} className='img1'>
                                                    {items.user.profileImage !== "" ? (
                                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                    ) : (
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                    <div>
                                        <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default ParticipatedEvents
