import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const EventDetails = () => {
    const { t, i18n } = useTranslation()

    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const { eventId } = useParams();

    const [eventDetails, setEventDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getSingleEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/SingleEventDetails/${eventId}`);
            const data = response.data;
            if (data.status === 1) {
                setEventDetails(data);
            } else {
                console.error('Error: Event data not found');
            }
        } catch (error) {
            console.log('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (eventId) {
            getSingleEvent();
        }
    }, [eventId]);

    if (loading) {
        return <></>;
    }
    
    

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={t("Event Details")} backpage={'/'} />
                <div className='padding2'>
                    <div className='container'>
                        {/* You can uncomment the following line when the eventDetails.events.images[0] exists */}
                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${eventDetails.events.images[0]}`} crossOrigin="anonymous" className="img-fluid w-100 rounded-4" alt="" />
                        <div className='mt-4'>
                            <h4 className='fw-bold mb-3'>
                                {i18n.language === 'en'? eventDetails?.events?.title_eng
                                : eventDetails?.events?.title_jap}
                            </h4>
                            {/* <p>Type: Lorem Ispum</p> */}
                            {
                                eventDetails.events && eventDetails.events.date_times.map((items, ind) => {
                                    return <h6 key={ind}>
                                        <FaRegCalendarAlt className='me-1' />
                                        <span className='align-middle text-white'>
                                            {new Date(items.dates).toLocaleDateString()}
                                        </span>
                                        <FaRegClock className='me-1 ms-4' />
                                        <span className='align-middle text-white'>
                                            {items.start_time} to {items.end_time}
                                        </span>
                                    </h6>
                                })
                            }

                            <p className='fw-semibold text-white mb-2 mt-4'>{t('Rules Description')}:</p>
                            <pre>{i18n.language === 'en' ? eventDetails.events.rules_eng : eventDetails.events.rules_jap}</pre>

                            <div className='d-flex align-items-center participants-plus'>
                                <div className='d-flex me-2'>
                                    {
                                        eventDetails?.events.participants?.slice(0, 5).map((items, ind) => (
                                            <div key={ind} className='img1'>
                                                <Link to={`/public-profile/${items.user._id}`}>{
                                                items.user.profileImage !== "" ? 
                                                    items.user.profileImage.includes('google') ? <img src={`${items.user.profileImage}`} className='img-fluid' alt="" />
                                                    : <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                    : <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                }</Link>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>
                                    <p className='mb-0'>+{eventDetails?.events.participants?.length || 0} {t('Participants')}</p>
                                </div>
                            </div>

                            <p className='fw-semibold text-white mb-2 mt-4'>{t('Gallery')}</p>

                            <div className='row g-3 gallery-row'>
                                {
                                    eventDetails?.events.images?.map((item, ind) => (
                                        <div key={ind} className='col-md-4 col-4'>
                                            <img src={`${process.env.REACT_APP_IMAGES_URL}/${item}`} crossOrigin="anonymous" className='img-fluid w-100 rounded-3 img1' alt="" />
                                        </div>
                                    ))
                                }
                            </div>

                            <div className='mt-4'>
                                {
                                    eventDetails.types == "upcoming" && <Link to={`/live-match/${eventId}`} className='btn btn1 btn-orange w-100'>{t('Join Event')}</Link>
                                }
                                {
                                    eventDetails.types == "past" && <Link to={`/match-details/${eventId}`} className='btn btn1 btn-orange w-100'>{t('View Results')}</Link>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    );
};

export default EventDetails;
