import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { FaRegCalendarAlt, FaRegClock, FaStar } from 'react-icons/fa'

const PastVoting = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={"profile2"} title={"Profile/Past Voting"} backpage={'/profile'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                    <span className='me-2'>Overall Ratings:</span> 
                                    <span>
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                    </span>
                                </h6>
                            </div>
                        </div>

                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                    <span className='me-2'>Overall Ratings:</span> 
                                    <span>
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                    </span>
                                </h6>
                            </div>
                        </div>

                        <div className='battle-box d-flex mt-0 mb-4'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                            </div>
                            <div className='txt align-self-center'>
                                <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                <h6 className='mb-0 fs-12 fw-medium'>
                                    <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                    <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                </h6>
                                <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                    <span className='me-2'>Overall Ratings:</span> 
                                    <span>
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                        <FaStar className='align-middle fs-10 me-1' />
                                    </span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default PastVoting
