import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAllContext } from '../context/AllContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const LiveEvent = () => {
    const { t, i18n } = useTranslation()

    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const params = useParams()

    const { getAllBattle, allBattle } = useAllContext()

    const [liveBattle, setLiveBattle] = useState({});
    const liveBattleFind = allBattle && allBattle.battles && allBattle?.battles.find(battle => battle.battle_status === "live");
    const liveBattleFindNext = allBattle && allBattle.battles && allBattle?.battles.find(battle => battle.battle_status === null);
    
    useEffect(() => {
        if (liveBattleFind) {
            setLiveBattle(liveBattleFind);
        } else if (liveBattleFindNext){
            setLiveBattle(liveBattleFindNext);
        } else {
            setLiveBattle({});
        }
    }, [liveBattleFind, liveBattleFindNext])

    console.log(liveBattle);
    

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milliseconds, setMilliseconds] = useState(0);

    useEffect(() => {
        if (liveBattle && allBattle) {
            // Convert battle_time_start to a Date object
            const startTime = new Date(liveBattle.battle_time_start);

            // Calculate the end time by adding the battles_time (in minutes)
            const endTime = new Date(startTime.getTime() + allBattle.battles_time * 60000);

            const updateTimer = () => {
                const now = new Date();
                const timeDiff = endTime - now;

                if (timeDiff > 0) {
                    // Calculate remaining minutes, seconds, and milliseconds
                    const mins = Math.floor((timeDiff / 1000 / 60) % 60);
                    const secs = Math.floor((timeDiff / 1000) % 60);
                    const millis = Math.floor((timeDiff % 1000) / 100); // To show in hundreds of ms

                    // Update individual states
                    setMinutes(mins);
                    setSeconds(secs);
                    setMilliseconds(millis);
                } else {
                    // If time is up
                    setMinutes(0);
                    setSeconds(0);
                    setMilliseconds(0);
                    clearInterval(intervalId); // Stop the interval
                }
            };

            // Declare intervalId here before it's used
            const intervalId = setInterval(updateTimer, 100);

            // Update the timer immediately on mount
            updateTimer();

            // Cleanup the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [allBattle, liveBattle]);



    const [votingStatus, setVotingStatus] = useState({});
    const getVotingStatus = async () => {
        const apiData = {
            eventId: params.eventId,
            battleId: liveBattle?._id,
            vote_send_userId: limitsUser._id
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VotingStatusActive`, apiData, {
                headers: {
                    Authorization: `${limitsUser.token}`
                }
            })
            const data = await response.data
            

            if(data.status === 0){
                // toast.error(data.message)
            }

            if(data.status === 1){
                toast.success(data.message)
                setVotingStatus(data)

            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
            toast.error(error.response.data.message)
        }
    }

    const totalVotes = votingStatus?.Player1 + votingStatus?.Player2;
    const player1Percentage = ((votingStatus?.Player1 / totalVotes) * 100).toFixed(2).replace(/\.?0+$/, '');
    const player2Percentage = ((votingStatus?.Player2 / totalVotes) * 100).toFixed(2).replace(/\.?0+$/, '');

    useEffect(() => {
        if (liveBattle?._id) {
            getVotingStatus()
        }
    }, [liveBattle])

    useEffect(() => {
        // getAllBattle(params.eventId)

        // Set up an interval to fetch data every second (1000ms)
        const intervalId = setInterval(() => {
            getAllBattle(params.eventId);
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [params.eventId])
    

    return (
        <>
            <div className='main-content p-0 liveevent-content'>
                { liveBattleFind || liveBattleFindNext ?
                    <div className='padding2 live-section h-100'>
                        <div className='container-fluid h-100'>
                            <div className='live1'>
                                <div className='row gx-3 h-100'>
                                    <div className='col-md-6 col-12 mb-4 mt-md-0'>
                                        <div className='card card1 h-100 bg-transparent border-0'>
                                            <div className='card-body'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12 mb-4 mt-md-0'>
                                        <div className='card card1 h-100 bg-transparent border-0'>
                                            <div className='card-body'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row gx-2 text-center'>
                                <div className='col-md-4 col-5'>
                                    <div className="d-flex align-items-center">
                                        <div className='me-4'>
                                            {liveBattle?.battle1?.profileImage ? 
                                                liveBattle?.battle1?.profileImage.includes('google') ? <img src={`${liveBattle.battle1.profileImage}`} className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                                : <img src={`${process.env.REACT_APP_IMAGES_URL}/${liveBattle.battle1.profileImage}`} crossOrigin="anonymous" className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                                :<img src={require('../assets/images/user-img.png')} className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                            }
                                        </div>
                                        <div>
                                            <h6 className='mb-0 text-white display-5 fw-semibold'>{liveBattle?.battle1?.username}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-2 align-self-center'>
                                    <h4 className='display-3 fw-semibold text-center mb-0'>
                                        <span className='d-inline-block me-1'>{String(minutes).padStart(2, '0')}</span>:
                                        <span className='d-inline-block' style={{"minWidth": "65px"}}>{seconds < 10 ? '0' : ''}{seconds}</span>.
                                        <span className="d-inline-block small" style={{"minWidth": "60px"}}>{String(milliseconds).padStart(2, '0')}</span>
                                    </h4>
                                </div>
                                <div className='col-md-4 col-5'>
                                <div className="d-flex align-items-center justify-content-end">
                                        <div>
                                            <h6 className='mb-0 text-white display-5 fw-semibold'>{liveBattle?.battle2?.username}</h6>
                                        </div>
                                        <div className='ms-4'>
                                            {liveBattle?.battle2?.profileImage ? 
                                                liveBattle?.battle2?.profileImage.includes('google') ? <img src={`${liveBattle.battle2.profileImage}`} className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                                :<img src={`${process.env.REACT_APP_IMAGES_URL}/${liveBattle.battle2.profileImage}`} crossOrigin="anonymous" className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                                :<img src={require('../assets/images/user-img.png')} className='img-fluid rounded-circle' alt="" style={{'width': '80px', 'minWidth': '80px', 'height': '80px', "objectFit": "cover"}} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row gx-3 text-center'>
                                <div className='col-md-4 col-12 mt-4'>
                                    <div className='card card1 h-100 bg-transparent border-0'>
                                        <div className='card-body'>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-12 mt-4'>
                                    <div className='mb-4' style={{"height" : "45px"}}>
                                        {
                                            liveBattle?.voting_bar === "show" && <>
                                                <div>
                                                    {/* <div className='d-flex justify-content-between mb-1'>
                                                        <div className=''>
                                                            <p className='mb-0 text-white text-center fw-light fs-14 mt-3'>{`${player1Percentage !== NaN ? player1Percentage : "0" }`}%</p>
                                                        </div>
                                                        <div className=''>
                                                            <p className='mb-0 text-white text-center fw-light fs-14 mt-3'>{`${player2Percentage !== NaN ? player2Percentage : "0" }`}%</p>
                                                        </div>
                                                    </div> */}
                                                    <div className="progress progress1 border-0">
                                                        <div className="progress-bar" role="progressbar" style={{"width": `${player1Percentage}%`, "background": "#0F78C5"}} aria-valuenow={player1Percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                        <div className="progress-bar" role="progressbar" style={{"width": `${player2Percentage}%`, "background": "#C12768"}} aria-valuenow={player2Percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <h4 className='fw-bold text-center mb-4'><span className='fw-medium me-4'>Theme:</span> 
                                        {
                                            i18n.language === 'en' ? <><span className='display-6 fw-semibold'>{liveBattle?.battle1_keyword_eng}</span> 
                                            <span className='display-6 mx-4'>+</span> 
                                            <span className='display-6 fw-semibold'>{liveBattle?.battle2_keyword_eng}</span></>
                                            : <><span className='display-6 fw-semibold'>{liveBattle?.battle1_keyword_jap}</span> 
                                            <span className='display-6 mx-4'>+</span> 
                                            <span className='display-6 fw-semibold'>{liveBattle?.battle2_keyword_jap}</span></>
                                        }
                                    </h4>

                                    <hr className='opacity-100' />
                                    <h4 className='mb-0 text-white text-center display-5 fw-semibold'>Battle Started!</h4>
                                    <hr className='opacity-100' />
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center me-3'>
                                            <img src={require('../assets/images/logo.png')} className='img-fluid logo1 me-3' alt="" style={{"height": "35px"}} />
                                            <h4 className='mb-0 text-white fw-medium'>{i18n.language === 'en' ? allBattle?.title_eng : allBattle?.title_jap}</h4>
                                        </div>
                                        <div>
                                            <h4 className='mb-0 text-white text-center fw-medium'>{liveBattle?.battle_name}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-12 mt-4'>
                                    <div className='card card1 h-100 bg-transparent border-0'>
                                        <div className='card-body'>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :<div className='text-center w-100 vh-100 d-flex flex-column justify-content-center'>
                        <h4 className='display-1 fw-semibold text-center mb-0'>Please wait for the next battle</h4>
                    </div>
                }
                    
            </div>
        </>
    )
}

export default LiveEvent
