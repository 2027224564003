
const reducer = (state, action) => {
    switch (action.type) {
        case "setUpcomingEvent":
            return {
                ...state,
                upcomingEvent: action.payload.events
            }

        case "setPastEvent":
            return {
                ...state,
                pastEvent: action.payload.events
            }

        case "setAllEvents":
            return {
                ...state,
                allEvents: action.payload.events
            }

        case "setAllBattle":
            return {
                ...state,
                allBattle: action.payload.event
            }
          
            
    
        default:
            return state;
    }
    
}

export default reducer