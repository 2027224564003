import React, { useState } from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link } from 'react-router-dom'
import { FaInstagram, FaLink, FaRegCalendarAlt, FaRegClock, FaRegStar, FaStar } from 'react-icons/fa'
import { MdEventNote } from 'react-icons/md'
import { CiEdit } from 'react-icons/ci'

const UserProfile = () => {

    const [inputValue, setInputValue] = useState({
        username: "Jess Bailey",
        email: "Johndoe1234@gmail.com"
    });

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value.trim()
        })
    }
    
    
    const [errors, setErrors] = useState({});
    const validate = () => {
        let errors = {};
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!inputValue.email) {
            errors.email = "Email is required"
        } else if (emailReg.test(inputValue.email) === false) {
            errors.email = "Invalid Email";
        }

        if (!inputValue.password) {
            errors.password = "Password is required"
        }
    
        return errors;
    };

    const formSubmit = async (e) => {
        e.preventDefault()

        const formErrors = validate();
        setErrors(formErrors);

        const loginData = {
            email: inputValue.email,
            password: inputValue.password
        }
        
        
        // if (Object.keys(formErrors).length === 0) {
        //     try {
        //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData)
        //         const data = await response.data

        //         const userData = {
        //             _id: data.result._id,
        //             username: data.result._idusername,
        //             email: data.result.email,
        //             profileImage: data.result.profileImage,
        //             type : data.result.type,
        //             token: data.token
        //         }

        //         if(data.status === 0){
        //             toast.error(data.message)
        //         }

        //         if(data.status === 1){
        //             localStorage.setItem('limitsUser', JSON.stringify(userData))
        //             toast.success(data.message)
        //             navigate('/')
        //         }

        //     } catch (error) {
        //         console.log('Error:', error.response.data.message);
        //         toast.error(error.response.data.message)
        //     }
        // }
    }

    return (
        <>
            <div className='main-content'>
                <Header page={"profile2"} title={"General User Profile"} backpage={'/profile'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='my-profile mb-5'>
                            <img src={require('../assets/images/profile.png')} className='img-fluid img1 d-block mx-auto' alt="" />
                        </div>
                        
                        <div className='profile-form'>
                            <form action="" className='form form1' onSubmit={formSubmit}>
                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.username} name="username" onChange={inputHandler} placeholder="Username"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.nickname} name="nickname" onChange={inputHandler} placeholder="Nick Name"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.email} name="email" onChange={inputHandler} placeholder="Email"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.country} name="country" onChange={inputHandler} placeholder="Country"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.city} name="city" onChange={inputHandler} placeholder="City"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.instagram} name="instagram" onChange={inputHandler} placeholder="Instagram"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.twitter} name="twitter" onChange={inputHandler} placeholder="Twitter"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.websiteLink} name="websiteLink" onChange={inputHandler} placeholder="Website Link"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="text" className="form-control" value={inputValue.shortbio} name="shortbio" onChange={inputHandler} placeholder="Short bio"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mb-3'>
                                    <div className="comments-input">
                                        <input type="password" className="form-control" value={inputValue.password} name="password" onChange={inputHandler} placeholder="Password"/>
                                        <CiEdit className='icon2' />
                                    </div>
                                    {/* {errors.email && <p className="error-text">{errors.email}</p> } */}
                                </div>

                                <div className='mt-4'>
                                    <button className='btn btn1 w-100 mt-4'>Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default UserProfile
