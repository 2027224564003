import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { GoDotFill } from 'react-icons/go'
import { Link, useParams } from 'react-router-dom'
import { useAllContext } from '../context/AllContext'
import { useTranslation } from 'react-i18next'

const LiveMatch = () => {
    const { t, i18n } = useTranslation()

    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const params = useParams()

    const { getAllBattle, allBattle } = useAllContext()

    const [liveBattle, setLiveBattle] = useState({});
    const liveBattleFind = allBattle && allBattle.battles && allBattle?.battles.find(battle => battle.battle_status === "live");

    useEffect(() => {
        if (liveBattleFind) {
            setLiveBattle(liveBattleFind);
        }
    }, [liveBattleFind])

    useEffect(() => {
        getAllBattle(params.eventId)
    }, [])


    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Art Battle | Person 1 vs Person 2"} backpage={'/match-details'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='card card1 overflow-hidden'>
                            <div className='card-body p-0'>
                                <div className='live-box position-relative'>
                                    <span className='live-tag'><GoDotFill /> {t('Live now')}</span>
                                    <div className='ratio ratio-16x9' style={{"maxHeight": "75vh"}} dangerouslySetInnerHTML={{ __html: allBattle?.youtube_emd }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='w-100 me-2'>
                                <p className='mb-1 fw-semibold text-white'>{i18n.language === 'en' ? allBattle?.title_eng : allBattle?.title_jap}</p>
                                <h6 className='mb-0 text-nowrap'>{allBattle?.date_times && new Date(allBattle?.date_times[0]?.dates).toLocaleDateString()}</h6>
                            </div>
                        </div>

                        <div className='mt-5'>
                            <Link to={`/voting/${params.eventId}`} className='btn btn1 btn-orange w-100' style={{'fontSize': "24px"}}>{t('Vote')}!</Link>
                        </div>


                        {/* <div className='card card1'>
                            <div className='card-body'>
                                <p className='mb-4 fw-semibold text-white text-center'>Comments</p>

                                <div>
                                    <form action="" className='form form1'>
                                        <div className='comments-input'>
                                            <input type="text" className='form-control' placeholder="Say Something..." />
                                            <div className="icon1">
                                                <FaTelegramPlane />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>

                                <div className='comments-box d-flex mt-4'>
                                    <div className='img'>
                                        <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium fs-12'><span className='text-white me-2'>Jakob Septimus</span> <span className='small'>30 min ago</span></p>
                                        <h6 className='mb-3 fs-12 text-fe'>Lorem ipsum odor amet, consectetuer adipiscing elit. Gravida magnis parturient placerat pharetra ullamcorper sollicitudin. Integer id interdum tellus odio facilisis maecenas.</h6>
                                        <h6 className='mb-0 fs-12'><FaRegHeart className='align-bottom fs-6 text-white me-1' /> <span className='align-bottom'>2</span> <BiMessageAltDots className='align-bottom fs-6 text-white ms-3 me-1' /> <span className='align-bottom'>3</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default LiveMatch
