import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const MatchDetails = () => {
    const { t, i18n } = useTranslation()

    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    const { eventId } = useParams();

    const [eventDetails, setEventDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getSingleEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/SingleEventDetails/${eventId}`, {
                headers: {
                    Authorization: limitsUser?.token
                }
            });
            const data = response.data;
            if (data.status === 1) {
                setEventDetails(data.events);
            } else {
                console.error('Error: Event data not found');
            }
        } catch (error) {
            console.log('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (eventId) {
            getSingleEvent();
        }
    }, [eventId]);

    if (loading) {
        return <></>;
    }

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={t("Art Battle Results")} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='card card1'>
                            <Link to="/live-event"><div className='card-body'>
                                <h6 className='mb-1 text-white text-center'>
                                {i18n.language === 'en'? eventDetails?.title_eng
                                : eventDetails?.title_jap}</h6>
                                <p className='fs-12 text-center mb-2'>{new Date(eventDetails.date_times[0].dates).toLocaleDateString()}</p>
                        
                                <div className='row gx-2 text-center align-items-center mb-4 pb-2'>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            {eventDetails.participants[0].user.profileImage !== "" ? (
                                                <img src={`${process.env.REACT_APP_IMAGES_URL}/${eventDetails.participants[0].user.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" />
                                            ) : (
                                                <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                            )}
                                            <h6 className='mb-0 text-white'>{eventDetails.participants[0].user.username}</h6>
                                            <p className='fs-12 mb-0 text-uppercase'>{eventDetails.participants[0].user.country}</p>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='mb-1 text-white fw-semibold'>1st : 2nd</h2>
                                        <span className='fs-12 badge1'>90+4</span>
                                    </div>
                                    <div className='col-4'>
                                        <div className='player1'>
                                            {eventDetails.participants[1].user.profileImage !== "" ? (
                                                <img src={`${process.env.REACT_APP_IMAGES_URL}/${eventDetails.participants[1].user.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" />
                                            ) : (
                                                <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                            )}
                                            <h6 className='mb-0 text-white'>{eventDetails.participants[1].user.username}</h6>
                                            <p className='fs-12 mb-0 text-uppercase'>{eventDetails.participants[1].user.country}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row gx-2 text-center align-items-center mt-3'>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>90</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='fs-12 mb-0 text-uppercase'>IDEA</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>75</p>
                                    </div>
                                </div>

                                <div className='row gx-2 text-center align-items-center mt-3'>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>85</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='fs-12 mb-0 text-uppercase'>SPEED</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>65</p>
                                    </div>
                                </div>

                                <div className='row gx-2 text-center align-items-center mt-3'>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>98</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='fs-12 mb-0 text-uppercase'>TECHNIQUE</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>89</p>
                                    </div>
                                </div>

                                <div className='row gx-2 text-center align-items-center mt-3'>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>95</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='fs-12 mb-0 text-uppercase'>STORYTELLING</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 text-white'>77</p>
                                    </div>
                                </div>
                            </div></Link>
                        </div>
                    </div>
                </div>

                <div className='padding2 pt-0'>
                    <div className='container'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='w-100 me-2'>
                                <p className='mb-0 fw-semibold text-white'>{t('Participants')}</p>
                            </div>
                            <div className='text-end'>
                                <h6 className='mb-0 text-nowrap'><Link to='' className='link'>{t('See All')}</Link></h6>
                            </div>
                        </div>

                        {
                            eventDetails?.participants?.map((items, ind) => (
                                <div className='participants-box d-flex' key={ind}>
                                    <div className='img'>
                                        <Link to={`/public-profile/${items.user._id}`}>{items.user.profileImage !== "" ? (
                                            <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid img1' alt="" />
                                        ) : (
                                            <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                                        )}</Link>
                                    </div>
                                    <div className='txt'>
                                        <p className='mb-2 fw-medium text-white'><Link to={`/public-profile/${items.user._id}`}>{items.user.username}</Link></p>
                                        <h6 className='mb-0'>{items.user.city}, {items.user.country}</h6>
                                    </div>
                                </div>
                            ))
                        }
                        
                        
                        
                        {/* <div className='participants-box d-flex'>
                            <div className='img'>
                                <img src={require('../assets/images/user-img.png')} className='img-fluid img1' alt="" />
                            </div>
                            <div className='txt'>
                                <p className='mb-2 fw-medium text-white'>First Name “Nickname” Last Name</p>
                                <h6 className='mb-0'>LOCATION</h6>
                            </div>
                        </div> */}
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default MatchDetails
