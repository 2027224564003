import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { Link } from 'react-router-dom'
import { FaInstagram, FaLink, FaRegCalendarAlt, FaRegClock, FaRegStar, FaStar } from 'react-icons/fa'
import { MdEventNote } from 'react-icons/md'

const PublicProfile = () => {
    return (
        <>
            <div className='main-content'>
                <Header page={"profile"} title={"My Profile"} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>
                        <div className='my-profile mb-5'>
                            <img src={require('../assets/images/profile.png')} className='img-fluid img1 d-block mx-auto' alt="" />
                        
                            <div className='text-center mt-4'>
                                <h5 className='mb-1'>Jess Bailey</h5>
                                <h6 className='fs-12'>Profile bio information will be here!</h6>
                                
                                <div className='mt-3 mb-4 d-flex justify-content-center align-items-center'>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>Japan</h6>
                                        <h6 className='mb-0 fs-12'>Country</h6>
                                    </div>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>29</h6>
                                        <h6 className='mb-0 fs-12'>Past Voting</h6>
                                    </div>
                                    <div className='w-100'>
                                        <h6 className='fw-semibold text-white mb-1'>43</h6>
                                        <h6 className='mb-0 fs-12'>Participated Events</h6>
                                    </div>
                                </div>

                                <h6 className='fs-12 mb-2 text-nowrap ellipsis1'><FaLink className='me-1 align-middle' /><span className='align-middle'>http//www.jessbailey.com/38/profile/q9nxdhfhfhjk</span></h6>
                                <h6 className='fs-12 mb-2 text-nowrap ellipsis1'><FaInstagram className='me-1 align-middle' /><span className='align-middle'>http//www.intagram.com/jessbailey922</span></h6>
                            </div>
                        </div>
                        
                        <div className='mt-5'>
                            <ul className="nav nav-tabs nav2 border-0 mb-4 flex-nowrap" id="myTab" role="tablist">
                                <li className="nav-item w-100" role="presentation">
                                    <button className="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true"><MdEventNote className='align-middle me-2' /><span className='align-middle'>Participated Events</span></button>
                                </li>
                                <li className="nav-item w-100" role="presentation">
                                    <button className="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false"><FaRegStar className='align-middle me-2' /><span className='align-middle'>Past Voting</span></button>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">

                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <span className='me-3'>Type: Loremipsum</span> 
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                                <div className='d-flex me-2'>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                </div>
                                                {/* <div className='d-flex me-2'>
                                                    {
                                                        items?.participants?.slice(0, 5).map((items, ind) => (
                                                            <div key={ind} className='img1'>
                                                                {items.user.profileImage !== "" ? (
                                                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                                ) : (
                                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                                )}
                                                            </div>
                                                        ))
                                                    }
                                                </div> */}
                                                <div>
                                                    <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <span className='me-3'>Type: Loremipsum</span> 
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                                <div className='d-flex me-2'>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                </div>
                                                {/* <div className='d-flex me-2'>
                                                    {
                                                        items?.participants?.slice(0, 5).map((items, ind) => (
                                                            <div key={ind} className='img1'>
                                                                {items.user.profileImage !== "" ? (
                                                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                                ) : (
                                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                                )}
                                                            </div>
                                                        ))
                                                    }
                                                </div> */}
                                                <div>
                                                    <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <span className='me-3'>Type: Loremipsum</span> 
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                                <div className='d-flex me-2'>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='img1'>
                                                        <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                    </div>
                                                </div>
                                                {/* <div className='d-flex me-2'>
                                                    {
                                                        items?.participants?.slice(0, 5).map((items, ind) => (
                                                            <div key={ind} className='img1'>
                                                                {items.user.profileImage !== "" ? (
                                                                    <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                                ) : (
                                                                    <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                                )}
                                                            </div>
                                                        ))
                                                    }
                                                </div> */}
                                                <div>
                                                    <p className='mb-0 fs-12 fw-medium'>+5 Participants</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                </div>

                                <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                                <span className='me-2'>Overall Ratings:</span> 
                                                <span>
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                </span>
                                            </h6>
                                        </div>
                                    </div>

                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                                <span className='me-2'>Overall Ratings:</span> 
                                                <span>
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                </span>
                                            </h6>
                                        </div>
                                    </div>

                                    <div className='battle-box d-flex'>
                                        <div className='img'>
                                            <img src={require('../assets/images/user-img.png')} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                        </div>
                                        <div className='txt align-self-center'>
                                            <h6 className='mb-2 text-white fw-medium'>Ultricies turpis semper tortor curae event name</h6>
                                            <h6 className='mb-0 fs-12 fw-medium'>
                                                <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>29 Jul 2024</span> 
                                                <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>12pm - 7pm</span>
                                            </h6>
                                            <h6 className='mb-0 fs-12 fw-medium mt-2'>
                                                <span className='me-2'>Overall Ratings:</span> 
                                                <span>
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                    <FaStar className='align-middle fs-10 me-1' />
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default PublicProfile
