import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const RoulettePublic = () => {
    const { t, i18n } = useTranslation()
    
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));

    const params = useParams();

    const [eventDetails, setEventDetails] = useState([]);
    const [rouletteDetails, setRouletteDetails] = useState([]);
    const [battleDetails, setBattleDetails] = useState([]);
    

    const [firstSelection, setFirstSelection] = useState(null);
    const [secondSelection, setSecondSelection] = useState(null);
    const [isSpinningLeft, setIsSpinningLeft] = useState(false); // Left spinner control
    const [isSpinningRight, setIsSpinningRight] = useState(false); // Right spinner control

    // Function to start both spinners
    const startBothSpinners = () => {
        if (!isSpinningLeft && !isSpinningRight) {
            setIsSpinningLeft(true);
            setIsSpinningRight(true);
        }
    };

    // Function to stop the left spinner
    const stopLeftSpinner = () => {
        if (isSpinningLeft) {
            finalizeSelection('left');
            setIsSpinningLeft(false);
        }
    };

    // Function to stop the right spinner
    const stopRightSpinner = () => {
        if (isSpinningRight) {
            finalizeSelection('right');
            setIsSpinningRight(false);
        }
    };

    // Continuously spin while isSpinningLeft is true
    useEffect(() => {
        if (isSpinningLeft) {
            const spin = setInterval(() => {
                const firstIndex = Math.floor(Math.random() * rouletteDetails?.keywords?.length);
                setFirstSelection(rouletteDetails.keywords[firstIndex]);
            }, 100); // Update every 100ms for continuous spinning

            return () => clearInterval(spin);
        }
    }, [isSpinningLeft, rouletteDetails]);

    // Continuously spin while isSpinningRight is true
    useEffect(() => {
        if (isSpinningRight) {
            const spin = setInterval(() => {
                const secondIndex = Math.floor(Math.random() * rouletteDetails.keywords.length);
                setSecondSelection(rouletteDetails.keywords[secondIndex]);
            }, 100); // Update every 100ms for continuous spinning

            return () => clearInterval(spin);
        }
    }, [isSpinningRight, rouletteDetails]);

    // Finalize the selection when stopping
    const finalizeSelection = (spinner) => {
        if (spinner === 'left') {
            const firstIndex = Math.floor(Math.random() * rouletteDetails.keywords.length);
            setFirstSelection(rouletteDetails.keywords[firstIndex]);
        } else if (spinner === 'right') {
            const secondIndex = Math.floor(Math.random() * rouletteDetails.keywords.length);
            setSecondSelection(rouletteDetails.keywords[secondIndex]);
        }
    };

    const getEventSingleBattleRoulette = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EventSingleBattleRouletteGet/${params.eventId}/${params.battelId}`, {
                headers: {
                    Authorization: `${limitsUser.token}`
                }
            });
            const data = await response.data;

            if (data.status === 0) {
                toast.error(data.message);
            }

            if (data.status === 1) {
                setEventDetails(data.event);
                setRouletteDetails(data.Roulette);
                setBattleDetails(data.updatedBattle);
            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
            toast.error(error.response.data.message);
        }
    };
    

    const submitBattleKeyword = async () => {
        const apiData = {
            eventId: params.eventId,
            battleId: params.battelId,
            battle_status: 'live',
            battle1_keyword_eng: firstSelection.keyword_eng1,
            battle1_keyword_jap: firstSelection.keyword_jap1,
            battle2_keyword_eng: secondSelection.keyword_eng2,
            battle2_keyword_jap: secondSelection.keyword_jap2
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/EventSingleBattleKeyUpdate`, apiData, {
                headers: {
                    Authorization: `${limitsUser.token}`
                }
            });
            const data = await response.data;

            if (data.status === 0) {
                toast.error(data.message);
            }

            if (data.status === 1) {
                // toast.success(data.message);
                getEventSingleBattleRoulette();
            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        getEventSingleBattleRoulette();
    }, []);

    useEffect(() => {
        if (!isSpinningLeft && !isSpinningRight && firstSelection && secondSelection) {
            submitBattleKeyword();
        }
    }, [isSpinningLeft, isSpinningRight, firstSelection, secondSelection]);

    
    return (
        <>
            <div className='main-content py-0'>
                <div className='padding2'>
                    <div className='container'>
                        {/* <h4 className='fw-bold text-center'>Limits</h4> */}
                        <img src={require('../assets/images/logo.png')} className='img-fluid logo1 mx-auto d-block' alt="" style={{"height": "55px"}} />
                        <div className='card card1 mt-3 bg-transparent border-0'>
                            <div className='card-body p-0'>
                                <div className='d-flex justify-content-around' >
                                    {/* Left Spinner */}
                                    <div className='w-50'>
                                        <div className='spin-maindiv' onClick={isSpinningLeft ? stopLeftSpinner : startBothSpinners}>
                                            <div className={`spin-div ${isSpinningLeft ? 'spin-active' : 'overflow-hidden'}`}>
                                                {!isSpinningLeft && (battleDetails?.battle1_keyword_eng || battleDetails?.battle2_keyword_eng || firstSelection !== null) ? (
                                                    <div className='text-center'>
                                                        <h4 className='mb-0 display-5 fw-semibold'>
                                                            {firstSelection !== null 
                                                                ? <span>{firstSelection.keyword_eng1} <br /> {firstSelection.keyword_jap1}</span>
                                                                : <span>{battleDetails?.battle1_keyword_eng} <br /> {battleDetails?.battle1_keyword_jap}</span>
                                                            }
                                                        </h4>
                                                    </div>
                                                ) : <div className='roulette-div'>
                                                    {rouletteDetails.keywords && rouletteDetails.keywords.map((item, index) => (
                                                        <div key={index} className={`spin-item display-5 fw-semibold ${item === firstSelection && !isSpinningLeft ? 'highlight' : ''}`}>
                                                            {item.keyword_eng1} <br />
                                                            {item.keyword_jap1}
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-spin'></div>
                                    {/* Right Spinner */}
                                    <div className='w-50'>
                                        <div className='spin-maindiv' onClick={isSpinningRight ? stopRightSpinner : startBothSpinners}>
                                            <div className={`spin-div ${isSpinningRight ? 'spin-active' : 'overflow-hidden'}`}>
                                                {!isSpinningRight && (battleDetails?.battle1_keyword_eng || battleDetails?.battle2_keyword_eng || secondSelection !== null) ? (
                                                    <div className='text-center'>
                                                        <h4 className='mb-0 display-5 fw-semibold'>
                                                            {secondSelection !== null 
                                                                ? <span>{secondSelection.keyword_eng2} <br /> {secondSelection.keyword_jap2}</span>
                                                                : <span>{battleDetails?.battle2_keyword_eng} <br /> {battleDetails?.battle2_keyword_jap}</span>
                                                            }
                                                        </h4>
                                                    </div>
                                                ) : <div className='roulette-div'>
                                                    {rouletteDetails.keywords && rouletteDetails.keywords.map((item, index) => (
                                                        <div key={index} className={`spin-item display-5 fw-semibold ${item === secondSelection && !isSpinningRight ? 'highlight' : ''}`}>
                                                            {item.keyword_eng2} <br />
                                                            {item.keyword_jap2}
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='mt-4 text-center'>
                                <h2 className='text-white fw-normal'>
                                    <span className='mx-1 d-inline-block me-3'>{i18n.language === 'en' ? eventDetails.title_eng : eventDetails.title_jap}</span>
                                </h2>
                            </div>
                            {/* {!isSpinningLeft && !isSpinningRight && (battleDetails?.battle1_keyword_eng || battleDetails?.battle2_keyword_eng) && (
                                <div className='mt-4 text-center'>
                                    <h4 className='text-white'>
                                        <span className='mx-1 d-inline-block me-3'>{battleDetails?.battle1_keyword_eng} <br /> {battleDetails?.battle1_keyword_jap}</span>
                                        <span className='mx-1 d-inline-block'>{battleDetails?.battle2_keyword_eng} <br /> {battleDetails?.battle2_keyword_jap}</span>
                                    </h4>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoulettePublic;
