import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import './assets/css/style.css'
import './assets/css/responsive.css'

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'

import 'react-calendar/dist/Calendar.css';

import Home from './views/Home';
import Signin from './views/Signin'
import Signup from './views/Signup'
import ForgotPassword from './views/ForgotPassword'
import UserType from './views/UserType'
import MatchDetails from './views/MatchDetails'
import LiveEvent from './views/LiveEvent'
import EventHistory from './views/EventHistory'
import Profile from './views/Profile'
import PublicProfile from './views/PublicProfile'
import PrivateRoute from './auth/PrivateRoute'
import PublicRoute from './auth/PublicRoute'
import EventDetails from './views/EventDetails'
import Limits from './views/Limits'
import Voting from './views/Voting'
import Roulette from './views/Roulette'
import CalendarPage from './views/CalendarPage'
import PastVoting from './views/PastVoting'
import ParticipatedEvents from './views/ParticipatedEvents'
import UserProfile from './views/UserProfile'
import RoulettePublic from './views/RoulettePublic'
import LiveMatch from './views/LiveMatch'

function App() {
  return (
    <>
      <Router basename='/'>

        <Routes>
          
          <Route exect path="/" element={<Home />} />
          <Route exect path="/event-details/:eventId" element={<EventDetails />} />
          <Route exect path="/match-details/:eventId" element={<MatchDetails />} />
          <Route exect path="/calendar" element={<CalendarPage />} />
          <Route exect path="/roulette/:eventId/:battelId" element={<Roulette />} />
          <Route exect path="/roulette-public/:eventId/:battelId" element={<RoulettePublic />} />
          <Route exect path="/live-event/:eventId" element={<LiveEvent />} />

          <Route element={<PrivateRoute />} >
            <Route exect path="/live-match/:eventId" element={<LiveMatch />} />
            <Route exect path="/voting/:eventId" element={<Voting />} />
            <Route exect path="/event-history" element={<EventHistory />} />
            <Route exect path="/profile" element={<Profile />} />
            <Route exect path="/user-profile" element={<UserProfile />} />
            <Route exect path="/public-profile/:userId" element={<PublicProfile />} />
            <Route exect path="/past-voting" element={<PastVoting />} />
            <Route exect path="/participated-events" element={<ParticipatedEvents />} />
            <Route path="*" element={<Home />} />
          </Route>

          <Route element={<PublicRoute />} >
            <Route exect path="/limits" element={<Limits />} />
            <Route exect path="/user-type" element={<UserType />} />
            <Route exect path="/signin" element={<Signin />} />
            <Route exect path="/signup/:userType" element={<Signup />} />
            <Route exect path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<UserType />} />
          </Route>

        </Routes>

      </Router>

      <ToastContainer position="bottom-center" autoClose={2000} />
    </>
  );
}

export default App;
