import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';

import Calendar from 'react-calendar';
import { useAllContext } from '../context/AllContext';


const CalendarPage = () => {
    const limitsUser = JSON.parse(localStorage.getItem('limitsUser'));
    
    const { getAllEvents, allEvents } = useAllContext()

    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date(today.setHours(23, 59, 59, 999));

    const [value, onChange] = useState([startOfToday, endOfToday]);

    const filteredEvents = allEvents.filter(event => {
        const eventDate = new Date(event.dates);
        
        const startDate = new Date(value[0]).setHours(0, 0, 0, 0); 
        const endDate = new Date(value[1]).setHours(23, 59, 59, 999);
        
        return eventDate >= startDate && eventDate <= endDate;
    });

    useEffect(() => {
        getAllEvents()
    }, [])

    

    return (
        <>
            <div className='main-content'>
                <Header page={""} title={"Calendar view"} backpage={'/'} />

                <div className='padding2'>
                    <div className='container'>

                        <div className='calendar-box p-0 mx-auto'>
                            <Calendar 
                                onChange={onChange} 
                                value={value} 
                                selectRange={true}
                                className={'mx-auto'}
                            />
                        </div>

                        <div className='mt-5'>
                            {
                                filteredEvents.length > 0 ? filteredEvents?.map((items, ind) => {
                                    return <div key={ind} className='battle-box d-flex'>
                                    <div className='img'>
                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.images[0]}`} crossOrigin="anonymous" className="img-fluid img1" alt="" />
                                    </div>
                                    <div className='txt align-self-center'>
                                        <h6 className='mb-2 text-white fw-medium'>{items.title}</h6>
                                        <h6 className='mb-0 fs-12 fw-medium'>
                                            <span className='me-3'>Type: Loremipsum</span> 
                                            <FaRegCalendarAlt className='align-bottom fs-6 me-1' /> <span className='align-bottom me-3'>{new Date(items.dates).toLocaleDateString()}</span> 
                                            <FaRegClock className='align-bottom fs-6 me-1' /> <span className='align-bottom'>{items.times}</span>
                                        </h6>
                                        <div className='d-flex align-items-center participants-plus calander-participants mt-2'>
                                            <div className='d-flex me-2'>
                                                {
                                                    items?.participants?.slice(0, 5).map((items, ind) => (
                                                        <div key={ind} className='img1'>
                                                            {items.user.profileImage !== "" ? (
                                                                <img src={`${process.env.REACT_APP_IMAGES_URL}/${items.user.profileImage}`} crossOrigin="anonymous" className='img-fluid' alt="" />
                                                            ) : (
                                                                <img src={require('../assets/images/user-img.png')} className='img-fluid' alt="" />
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div>
                                                <p className='mb-0 fs-12 fw-medium'>+{items?.participants?.length || 0} Participants</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }): <div className='text-center'><p>No Records</p></div>
                            }
                        </div>
                    </div>
                </div>

                <Menu />
            </div>
        </>
    )
}

export default CalendarPage
